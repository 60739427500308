import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layouts'
import classes from '../layouts/Pages.module.css'

export default ({ location }) => (
    <Layout location={location}>
        <Helmet>
            <title>Ligne de crise </title>
            <meta name="description" content="Si vous êtes en crise ou en danger, veuillez utiliser les ressources suivantes pour obtenir de l'aide immédiatement. Urgences psychiatriques: 032 755 15 15"/>
        </Helmet>
        <div>
            <div style={{maxWidth: 700, margin: 'auto', padding: '40px 15px'}}>
                <div style={{textAlign: 'center'}}>
                    <h1>Besoin d'aide?</h1>
                    <h3 style={{fontWeight: 300}}>Si vous êtes en crise ou en danger, veuillez utiliser les ressources suivantes pour obtenir de l'aide immédiatement</h3>
                </div>
                <div style={{padding: 20, boxShadow: '0 1px 4px 0 rgba(205,205,205,0.8'}}>
                    <h3>Urgences</h3>
                    <table className={classes.tableWrap}>
                        <tr>
                            <td>Urgences:</td>
                            <td>144</td>
                        </tr>
                        <tr>
                            <td>Urgences psychiatriques:</td>
                            <td><a target="_blank" rel="noreferrer" href="tel:+41327551515">032 755 15 15</a></td>
                        </tr>
                        <tr>
                            <td>Services de garde:</td>
                            <td><a target="_blank" rel="noreferrer" href="tel:0848134134">0848 134 134</a></td>
                        </tr>
                        <tr>
                            <td>Hotline pédiatrique:</td>
                            <td><a target="_blank" rel="noreferrer" href="tel:+41327133848">032 713 38 48</a></td>
                        </tr>

                    </table>

                </div>
            </div>
        </div>

    </Layout>
)